import React, { useState } from 'react';
import { reportingFilterComparisonConst } from '../../../../../constants/reportingFilterComparisonConst';
import { reportingFilterDateRangesConst } from '../../../../../constants/reportingFilterDateRangesConst';
import Tooltip from '../../../../Invoices/InvoiceCreateManual/Tooltip';

function CliInvCompGraphFilter({
    selectedDateRange,
    selectedComparison,
    selectedFrequency,
    selectedYearRange,
    selectedMonthRange,
    selectedComparisonYear,
    selectedComparisonMonth
}) {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    const [showTooltip, setShowTooltip] = useState(true);

    const handleTooltipToggle = () => {
        setShowTooltip(!showTooltip);
    };

    const getComparisonTitle = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
        const rangeTitles = {
            [reportingFilterDateRangesConst.This_Year]: `Current Year (${currentYear})`,
            [reportingFilterDateRangesConst.This_Quarter]: `This Quarter`,
            [reportingFilterDateRangesConst.First_Half]: `First Half`,
            [reportingFilterDateRangesConst.This_Month]: `This Month`,
            [reportingFilterDateRangesConst.Year_Selected]: selectedYearRange ? `Year ${selectedYearRange.getFullYear()}` : `Selected Year`,
            [reportingFilterDateRangesConst.Month_Selected]: selectedMonthRange ? `Month ${selectedMonthRange.toLocaleString('default', { month: 'long' })} ${selectedMonthRange.getFullYear()}` : `Selected Month`,
        };

        const comparisonTitles = {
            [reportingFilterComparisonConst.Last_Year]: `Last Year (${previousYear})`,
            [reportingFilterComparisonConst.Last_Quarter]: `Last Quarter`,
            [reportingFilterComparisonConst.Second_Half]: `Second Half`,
            [reportingFilterComparisonConst.Last_Month]: `Last Month`,
            [reportingFilterComparisonConst.Year_Select]: selectedComparisonYear ? `Year ${selectedComparisonYear.getFullYear()}` : `Previous Year`,
            [reportingFilterComparisonConst.Month_Select]: selectedComparisonMonth ? `Month ${selectedComparisonMonth.toLocaleString('default', { month: 'long' })} ${selectedComparisonMonth.getFullYear()}` : `Previous Month`,
        };

        return isCurrent ? rangeTitles[selectedRange] : comparisonTitles[selectedComparison];
    };

    const getDateRange = (isCurrent, selectedRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth) => {
        let dateRange = '';

        if (isCurrent) {
            switch (selectedRange) {
                case reportingFilterDateRangesConst.This_Year:
                    dateRange = `January 1, ${new Date().getFullYear()} - December 31, ${new Date().getFullYear()}`;
                    break;
                case reportingFilterDateRangesConst.This_Quarter:
                    dateRange = 'Start Date - End Date of Current Quarter';
                    break;
                case reportingFilterDateRangesConst.First_Half:
                    dateRange = `January 1, ${new Date().getFullYear()} - June 30, ${new Date().getFullYear()}`;
                    break;
                case reportingFilterDateRangesConst.This_Month:
                    dateRange = `Start Date - End Date of ${new Date().toLocaleString('default', { month: 'long' })}`;
                    break;
                case reportingFilterDateRangesConst.Year_Selected:
                    dateRange = 'Start Date - End Date of Selected Year';
                    break;
                case reportingFilterDateRangesConst.Month_Selected:
                    dateRange = 'Start Date - End Date of Selected Month';
                    break;
                default:
                    dateRange = 'Unknown range';
                    break;
            }
        } else {
            switch (selectedComparison) {
                case reportingFilterComparisonConst.Last_Year:
                    dateRange = `January 1, ${new Date().getFullYear() - 1} - December 31, ${new Date().getFullYear() - 1}`;
                    break;
                case reportingFilterComparisonConst.Last_Quarter:
                    dateRange = 'Start Date - End Date of Last Quarter';
                    break;
                case reportingFilterComparisonConst.Second_Half:
                    dateRange = `July 1, ${new Date().getFullYear() - 1} - December 31, ${new Date().getFullYear() - 1}`;
                    break;
                case reportingFilterComparisonConst.Last_Month:
                    dateRange = `Start Date - End Date of ${new Date().toLocaleString('default', { month: 'long', year: 'numeric' }, { month: 'long' })}`;
                    break;
                case reportingFilterComparisonConst.Year_Select:
                    dateRange = 'Start Date - End Date of Selected Comparison Year';
                    break;
                case reportingFilterComparisonConst.Month_Select:
                    dateRange = 'Start Date - End Date of Selected Comparison Month';
                    break;
                default:
                    dateRange = 'Unknown comparison range';
                    break;
            }
        }

        return dateRange;
    };

    return (
        <div className='top-outer-graph-report top-hours-per-client'>
            <div className="top-inner-graph">
                <div className="graph-title">
                    <div className='comparison-type'>
                        <div className='comparison-type-inner'>
                            <div className='comparison-type-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 7 1)" fill="white" stroke="#96A8BA" stroke-width="1.5" />
                                </svg>
                            </div>
                            <div className='comparison-type-title' onMouseEnter={handleTooltipToggle} onMouseLeave={handleTooltipToggle}>
                                {getComparisonTitle(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)}
                            </div>
                            <div className="tooltip-boxmain">
                                {showTooltip && (
                                    <Tooltip
                                        className="custom-tooltip"
                                        content={[
                                            {
                                                heading: getComparisonTitle(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth),
                                                content: getDateRange(true, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth),
                                                headingClass: "heading1tool",
                                                contentClass: "content1tool",
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='comparison-type-inner'>
                            <div className='comparison-type-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                    <circle cx="3" cy="3" r="3" transform="matrix(-1 0 0 1 7 1)" fill="white" stroke="#6479F8" stroke-width="1.5" />
                                </svg>
                            </div>
                            <div className='comparison-type-title'>
                                {getComparisonTitle(false, selectedDateRange, selectedComparison, selectedYearRange, selectedMonthRange, selectedComparisonYear, selectedComparisonMonth)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon-graph">
                    <div className="filter-comparison-graph">
                        <a href="#">YEAR / MONTH</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CliInvCompGraphFilter;

import React, { useEffect, useState } from "react";
// import "./ReportPersonal.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../ReportingPersonal/ReportingTimeOffRequest/ReportingTimeOffRequest.css";

import TimeOffRequestFilter from "./ReportingTimeOffRequest/ReportTimeOffRequestFilter";
import TimeOffReqReport from "./ReportingTimeOffRequest/TotalRequestsTimeOff";
import TimeOffReqReportApproved from "./ReportingTimeOffRequest/TotalApprovedRequestTimeOff";

import RequestHistoryGraph from "./ReportingTimeOffRequest/TimeOffRequestHistoryGraph";
import RequestHistoryGraphFilter from "./ReportingTimeOffRequest/TimeOffRequestHistoryGraphFilter";
import { useFetchExpenseReportDetails } from "../../../features/reporting/reportingFinancial/useReportingExpense";
import { ThreeDots } from "react-loader-spinner";
import { useFetchExpCategory } from "../../AppSettings/expenseCategoryApi";
import TimeOffRequestTable from "./ReportingTimeOffRequest/TimeOffRequestTable";
import DeniedRequestsTimeOff from "./ReportingTimeOffRequest/TotalDeniedRequestTimeOff";
import { useFetchLeaveTypes, useTimeOffRequests, useUserById } from "../../TimeOff/timeOffApi";
import CancelledRequestsTimeOff from "./ReportingTimeOffRequest/TotalCancelledRequestTimeOff";
import TimeOffRequestHistoryFilter from "./ReportingTimeOffRequest/GraphFilters/ReportTimeOffRequestHistory/ReportTimeOffRequestHistoryFilter";
import TimeOffRequestPerUserGraph from "./ReportingTimeOffRequest/TimeOffRequestPerUserGraph";
import TimeOffRequestPerUserFilter from "./ReportingTimeOffRequest/TimeOffRequestPerUserGraphFilter";
import TimeOffReqPerUserGraphFilter from "./ReportingTimeOffRequest/GraphFilters/ReportTimeOffRequestPerUser/ReportTimeOffReqPerUserFilter";

function TimeOffRequestReport() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [isGraphLoaded, setIsGraphLoaded] = useState(false);
  let token = null;
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: timeoffReq = [], isLoading: timeoffReqLoading, isError: timeoffReqError, refetch: torRefetch } = useTimeOffRequests(token);
  const { data: users = [], isLoading: userLoading, isError: usersError, refetch: userRefetch } = useUserById(token);
  const { data: leaveType = [], isLoading: leaveTypesLoading, isError: leaveTypeError, refetch: leaveTypeRefetch } = useFetchLeaveTypes(token);

  return (
    <div className="reporting-timeoff-request-outer-page client-report-outer">
      <div className="invoice-outer-invoice invoice-outer-invoice-reprt">
        <TimeOffRequestFilter
          timeoffReq={timeoffReq}
          users={users}
          leaveType={leaveType}
        />
      </div>

      <div className="repot-tatal-box-sec">
        <div className="total-request-outer">
          <TimeOffReqReport timeoffReq={timeoffReq} />
        </div>
        <div className="total-request-outer">
          <TimeOffReqReportApproved timeoffReq={timeoffReq} />
        </div>
        <div className="total-request-outer">
          <DeniedRequestsTimeOff timeoffReq={timeoffReq} />
        </div>
        <div className="total-request-outer">
          <CancelledRequestsTimeOff timeoffReq={timeoffReq} />
        </div>
      </div>

      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report border-line-bottom">
          <div className="top-graph">
            <TimeOffRequestHistoryFilter
              timeoffReq={timeoffReq}
              timeoffReqLoading={timeoffReqLoading}
              timeoffReqError={timeoffReqError}
            />
          </div>
          <RequestHistoryGraph
            timeoffReq={timeoffReq}
            timeoffReqLoading={timeoffReqLoading}
            timeoffReqError={timeoffReqError}
          />
        </div>
      </div>

      <div className="outer-report-graph-piechart">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="top-graph">
            <TimeOffReqPerUserGraphFilter
              timeoffReq={timeoffReq}
              timeoffReqLoading={timeoffReqLoading}
              timeoffReqError={timeoffReqError}
            />
          </div>
          <TimeOffRequestPerUserGraph
            timeoffReq={timeoffReq}
            timeoffReqLoading={timeoffReqLoading}
            timeoffReqError={timeoffReqError}
          />
        </div>
        <div className="pie-chart-right-no">

        </div>
      </div>
      <div className="outer-report-graph-piechart client-report-inner">
        <div className="graph-main-outer-time graph-main-outer-time-report">
          <div className="recent-inv-title">Time Off Requests</div>
          <div className="top-graph">
            <TimeOffRequestTable
              timeoffReq={timeoffReq}
              timeoffReqLoading={timeoffReqLoading}
              timeoffReqError={timeoffReqError}
            />
          </div>
        </div>
      </div>
      <div className="button-sec-invoice">
        <Link to="/user/time-off">View All</Link>
      </div>
    </div>
  );
}

export default TimeOffRequestReport;

import "./TimeTrackerTag.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import Delete from "../../../images/icons/delete.svg";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { logout, reset } from "../../../features/auth/authSlice.js";
import addicon from "../../../images/team-member-tab/add-icon.svg";
import crssrImage from "../../../images/singleinvoice/cross-red.svg";
import editimgblue from "../../../images/team-member-tab/edit-btn-blue.svg";
import {
  useCreateTag,
  useDeleteTag,
  useFetchTag,
  useUpdateTag,
} from "./tagApi.jsx";
import { reset as invoiceReset } from "../../../features/invoice/invoiceSlice.js";
import { reset as headerReset } from "../../../features/headerText/headerTextSlice.js";
import { reset as appSettingsReset } from "../../../features/appSettings/appSettingsSlice.js";

const TimeTrackerTag = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [tagCreate, setTagCreate] = useState("");
  const tagCreateRef = useRef(null);
  const [tagEdit, setTagEdit] = useState("");
  const [sortedTag, setSortedTag] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const { appSettings } = useSelector((state) => state.appSettings);
  const [filter, setFilter] = useState("");
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [checkedOption, setCheckedOption] = useState(null);
  const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
  const sortDropdownRef = useRef(null);

  const { data: tag = [], isLoading, isError } = useFetchTag(token);

  const deleteTagMutation = useDeleteTag(token);
  const createTagMutation = useCreateTag(token);
  const updateTagMutation = useUpdateTag(token);


  const selectSortOption = (option) => {
    setSelectedSortOption(option);
    setCheckedOption(option);
    setSortDropdownOpen(false);
    // console.log(`Selected option: ${option}`);
  };

  const toggleSortDropdown = () => {
    setSortDropdownOpen(!sortDropdownOpen);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        document.activeElement.blur();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setSortedTag(tag);
  }, [tag]);

  const sortData = (data, sortOption) => {
    switch (sortOption) {
      case 'By name: A - Z':
        return data.slice().sort((a, b) => a.tag_name.localeCompare(b.tag_name));
      case 'By name: Z - A':
        return data.slice().sort((a, b) => b.tag_name.localeCompare(a.tag_name));
      case 'Date: old to new':
        return data.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      case 'Date: new to old':
        return data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      default:
        return data;
    }
  };

  const sortedData = sortData(sortedTag, selectedSortOption);

  const filteredData = sortedData.filter((item) => {
    const searchTerm = filter.trim().toLowerCase();
    const hasMatch =
      item.tag_name.toLowerCase().includes(searchTerm);
    return hasMatch;
  });

  const handleOpenModal = (item) => {
    setIsModalOpen(true);
    setSelectedId(item._id);
    setTagEdit(item.tag_name);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId("");
    setTagEdit("");
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsSubmitLoading(true);
      await deleteTagMutation.mutateAsync(selectedId);
      setSortedTag((prevState) =>
        prevState.filter((tag) => tag._id !== selectedId)
      );
      toast.success("Tag removed successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      global.config.activityLog(
        window.location.href,
        "Tag",
        `Tag (${selectedId}) removed successfully`
      );
      handleCloseModal();
    } catch (error) {
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.log(error.response.data.message); // print server error message
    } finally {
      setIsSubmitLoading(false);
    }
    setIsDeleteModalOpen(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCreateSubmit = async (event) => {
    event.preventDefault();
    if (tagCreate === "") {
      toast.error("Tag cannot be empty!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      tagCreateRef.current.focus();
      return;
    }

    const requestData = {
      tag_name: tagCreate,
    };
    try {
      setIsSubmitLoading(true);
      const data = await createTagMutation.mutateAsync(requestData);
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Tag",
          `Something went wrong while adding Tag`
        );
      } else if (data.status === "exists") {
        toast.error("Tag with the same name already exists", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Tag",
          `Tag ${tagCreate} already exist`
        );
      } else {
        toast.success("Tag added successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Tag",
          `Tag ${tagCreate} added successfully`
        );
        setTagCreate("");
      }
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      toast.error("Something went wrong");
      global.config.activityLog(
        window.location.href,
        "Tag",
        `Something went wrong`
      );
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const response = await updateTagMutation.mutateAsync({
        selectedId,
        tag_name: tagEdit,
      });
      const data = response.data;
      if (data.status === "error") {
        toast.error("Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (data.status === "exists") {
        toast.error(`Tag with the "${tagEdit}" name already exists`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setSortedTag((prevState) => {
          const updatedTag = prevState.map((item) => {
            if (item._id === selectedId) {
              return { ...item, tag_name: tagEdit };
            }
            return item;
          });
          return updatedTag;
        });
        toast.success("Tag updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        global.config.activityLog(
          window.location.href,
          "Tag",
          `Tag "${tagEdit}" updated successfully`
        );
        handleCloseModal();
      }
    } catch (error) {
      console.log(error.response.data.message); // print server error message
      global.config.slackMessage(error.toString());
      if (error.response?.data?.status === "deleted") {
        localStorage.removeItem("user");
        dispatch(logout());
        dispatch(reset());
        dispatch(invoiceReset());
        dispatch(headerReset());
        dispatch(appSettingsReset());
        toast.error("This user is deleted!", {
          position: "bottom-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate("/");
        return;
      }
      alert("Something went wrong");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideSortDropdown = sortDropdownRef.current && sortDropdownRef.current.contains(event.target);

    if (!isClickInsideSortDropdown) {
      setSortDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('click', handleClickOutsideDropdown);
    };
  }, []);

  return (
    <div>
      <div className="div-outter-for-department">
        <div className="add-department-section top-filter-client-inner">
          <div className="top-filter-client-inner-left">
            <input
              type="text"
              placeholder={global.config.locate(
                appSettings && appSettings.language,
                "Add new tag"
              )}
              value={tagCreate}
              onChange={(e) => setTagCreate(e.target.value)}
              ref={tagCreateRef}
            />
            <button
              className="add-btn-in-department-b1"
              onClick={handleCreateSubmit}
              disabled={isSubmitLoading}
            >
              {isSubmitLoading ? (
                <div className="outter-load-table">
                  <ThreeDots
                    height="21"
                    width="25"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                <img src={addicon} alt="add" />
              )}
            </button>
          </div>
          <div className="top-filter-client-sort">

            <div className="custom-dropdown1">
              <div
                ref={sortDropdownRef}
                className={`custom-dropdown-user-new-outer custom-dropdown-select ${sortDropdownOpen ? 'open' : ''}`}
              >
                <div className="selected-option custom-dropdown-user-new" onClick={toggleSortDropdown}>
                  {selectedSortOption ? selectedSortOption : 'Sort By'}
                </div>
                {sortDropdownOpen && (
                  <div className="custom-dropdown-new">
                    <ul className="type-list">
                      <li
                        className={checkedOption === 'By name: A - Z' ? 'selected' : ''}
                        onClick={() => selectSortOption('By name: A - Z')}
                      >
                        <span className={`checkbox ${checkedOption === 'By name: A - Z' ? 'checked' : ''}`}></span>
                        By name: A - Z
                      </li>
                      <li
                        className={checkedOption === 'By name: Z - A' ? 'selected' : ''}
                        onClick={() => selectSortOption('By name: Z - A')}
                      >
                        <span className={`checkbox ${checkedOption === 'By name: Z - A' ? 'checked' : ''}`}></span>
                        By name: Z - A
                      </li>
                      <li
                        className={checkedOption === 'Date: old to new' ? 'selected' : ''}
                        onClick={() => selectSortOption('Date: old to new')}
                      >
                        <span className={`checkbox ${checkedOption === 'Date: old to new' ? 'checked' : ''}`}></span>
                        Date: old to new
                      </li>
                      <li
                        className={checkedOption === 'Date: new to old' ? 'selected' : ''}
                        onClick={() => selectSortOption('Date: new to old')}
                      >
                        <span className={`checkbox ${checkedOption === 'Date: new to old' ? 'checked' : ''}`}></span>
                        Date: new to old
                      </li>
                      {/* Add more sorting options as needed */}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="top-filter-client-sort-search">
              <div className="top-filter-client-search">
                <div className="search-sec">
                  <div className="search-container">
                    <input type="text" className="search-input" placeholder="Search by tag name" onChange={(e) => setFilter(e.target.value)} value={filter} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="department-section-listing">
          {isLoading === true ? (
            <div className="outter-load-table">
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#405BFF"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <>
              {filteredData.map((item, index) => (
                <div className="grid-item" key={item._id}>
                  <input
                    type="text"
                    className="input-tag"
                    value={item.tag_name}
                    readOnly
                  />
                  <button
                    className="edit-button"
                    onClick={() => handleOpenModal(item)}
                  >
                    <img src={editimgblue} alt="edit" />
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Edit"
                    )}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="modal-invite-main-outter">
        <Modal
          className="client-modal leave"
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="New TAG"
          style={{
            content: {
              height: "41%",
              width: "30%",
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              parent: document.querySelector(".admin-outer.time.tracker"),
            },
          }}
        >
          <div className="modal">
            <div className="modal-close-invite outter-modal-main">
              <a className="" onClick={handleCloseModal}>
                <img src={crssrImage} alt="Edit Icon" />{" "}
              </a>
            </div>
            <div className="modal-client-head">
              <h2 className="">
                {global.config.locate(
                  appSettings && appSettings.language,
                  "Edit Tag"
                )}
              </h2>
            </div>
            <div className="form-field-client-outer">
              <form onSubmit={handleUpdateSubmit}>
                <div className="form-field-client">
                  <label htmlFor="clientName" className="form-label">
                    {global.config.locate(
                      appSettings && appSettings.language,
                      "Tag Name"
                    )}
                  </label>
                  <input
                    type="text"
                    id="clientName"
                    name="clientName"
                    className="form-input"
                    value={tagEdit}
                    onChange={(e) => setTagEdit(e.target.value)}
                    required
                  />
                </div>
                {isSubmitLoading ? (
                  <div className="outter-load-table">
                    <ThreeDots
                      height="38"
                      width="40"
                      radius="9"
                      color="#6479f9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div className="form-field-client-btns">
                    <button
                      type="button"
                      className="delete-client"
                      onClick={handleDelete}
                      disabled={isSubmitLoading}
                    >
                      <img src={Delete} alt="Delete" />{" "}
                      {global.config.locate(
                        appSettings && appSettings.language,
                        "Delete"
                      )}
                    </button>
                    <Modal
                      className="delete-modal"
                      isOpen={isDeleteModalOpen}
                      onRequestClose={handleCancelDelete}
                      contentLabel="Confirm Delete"
                      style={{
                        content: {
                          height: "41%",
                          width: "30%",
                          position: "fixed",
                          top: "36%",
                          left: "50%",
                          transform: "translate(-24.75%, 0%)",
                          parent: document.querySelector(
                            ".admin-outer.time.tracker"
                          ),
                        },
                      }}
                    >
                      <div className="delete-modal-content">
                        <h2>
                          {global.config.locate(
                            appSettings && appSettings.language,
                            "Confirm_Delete"
                          )}
                        </h2>
                        <p>
                          {global.config.locate(
                            appSettings && appSettings.language,
                            "Are you sure you want to delete this Tag?"
                          )}
                        </p>
                        {isSubmitLoading ? (
                          <div className="outter-load-table">
                            <ThreeDots
                              height="38"
                              width="40"
                              radius="9"
                              color="#6479f9"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          <div className="delete-modal-buttons">
                            <button
                              className="delete"
                              onClick={handleConfirmDelete}
                              disabled={isSubmitLoading}
                            >
                              {global.config.locate(
                                appSettings && appSettings.language,
                                "Delete"
                              )}
                            </button>
                            <button onClick={handleCancelDelete}>
                              {global.config.locate(
                                appSettings && appSettings.language,
                                "Cancel"
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </Modal>
                    <button
                      type="submit"
                      className="save-changes"
                      disabled={isSubmitLoading}
                    >
                      {global.config.locate(
                        appSettings && appSettings.language,
                        "Save_changes"
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TimeTrackerTag;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ThreeDots } from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { calculateCategoryExpenses } from './Services/reportExpense';

const data = [
  { name: 'Rent', currentYear: 44, lastYear: 24 },
  { name: 'Utilities', currentYear: 55, lastYear: 39 },
  { name: 'Groceries', currentYear: 13, lastYear: 30 },
  { name: 'Transport', currentYear: 43, lastYear: 50 },
  { name: 'Entertainment', currentYear: 46, lastYear: 27 },
  { name: 'Healthcare', currentYear: 55, lastYear: 40 },
  { name: 'Miscellaneous', currentYear: 32, lastYear: 18 },
];

// const calculateXTicks = (data) => {
//   if (data.length === 0) return [];

//   const maxDataValue = Math.max(...data.map(item => Math.max(item.currentYear, item.lastYear)));
//   // const tickInterval = 10;
//   const tickInterval = Math.ceil(maxDataValue / 10); // Set the interval between ticks
//   const numOfTicks = Math.ceil(maxDataValue / tickInterval);
//   const ticks = [];

//   // for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
//   //   ticks.push(i * tickInterval);
//   // }
//   for (let i = 0; i <= numOfTicks + 4; i += tickInterval) {
//     ticks.push(i);
//   }
//   return ticks;
// };

const ExpenseHistory = ({ expenseData, expenseDataLoading, expenseDataError }) => {
  const reportingFinancialExpense = useSelector((state) => state.reportingExpense);
  const { forex } = useSelector((state) => state.forex);
  const settings = JSON.parse(localStorage.getItem("settings"));

  const currentYear = reportingFinancialExpense.expYear || new Date().getFullYear();
  const previousYear = currentYear - 1;
  const isCurrentYear = currentYear === new Date().getFullYear();

  const setReportingCurrency = useSelector((state) => state.reportingExpense.expCurrency);
  var currencies = "USD";
  if(setReportingCurrency && setReportingCurrency.length===1){
     currencies = setReportingCurrency[0];
  }else{
    currencies = settings.currency;
  }


  const currentYearExpenses = useMemo(() => calculateCategoryExpenses(expenseData, reportingFinancialExpense, currencies, forex, currentYear), [expenseData, reportingFinancialExpense, currencies, forex, currentYear]);
  const lastYearExpenses = useMemo(() => calculateCategoryExpenses(expenseData, reportingFinancialExpense, currencies, forex, previousYear), [expenseData, reportingFinancialExpense, currencies, forex, previousYear]);

  const processedData = Object.keys(currentYearExpenses).map(category => ({
    name: category,
    currentYear: parseFloat(currentYearExpenses[category].toFixed(2)) || 0,
    lastYear: parseFloat(lastYearExpenses[category].toFixed(2)) || 0
  }));

  if (expenseDataLoading) return <div className="outter-load-table">
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#6479f9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClassName=""
      visible={true}
    />
  </div>;
  if (expenseDataError) return <div>Error loading data.</div>;

  const calculateXTicks = () => {
    if (!processedData.length) return [];
    const maxDataValue = Math.max(...processedData.map(item => Math.max(item.currentYear, item.lastYear)));
    if (maxDataValue === 0) return [0];
    // const tickInterval = 10; // Set the interval between ticks
    const tickInterval = Math.ceil(maxDataValue / 10);
    const numOfTicks = Math.ceil(maxDataValue / tickInterval);
    const ticks = [];

    for (let i = 0; i <= numOfTicks + 4; i++) { // Adjust the number of ticks
      ticks.push(i * tickInterval);
    }

    return ticks;
  }
  // const xAxisTicks = calculateXTicks(processedData);
  const xAxisTicks = calculateXTicks();

  const calculateChartHeight = (dataLength) => {
    const rowHeight = 50; // Adjust this value to set the height per bar
    const baseHeight = 100; // Add some base height for padding and labels
    return dataLength * rowHeight + baseHeight;
  };

  return (
    <div className='graph-project-per-hour' style={{ width: '100%', height: Math.max(542, calculateChartHeight(processedData.length)), }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          layout="vertical"
          data={processedData}
          barSize={13} // Set the height of each bar
          margin={{
            top: 20,
            right: 42,
            left: 0, // Adjust the left margin to remove white space
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={true} />
          <XAxis
            type="number"
            domain={[0, 'dataMax + 10']}
            tickFormatter={(tick) => `${new Intl.NumberFormat('en-US', { style: 'currency', currency: currencies, }).format(0).replace(/[\d.,]/g, '')}${tick}`} 
            ticks={xAxisTicks}
          />
          <YAxis
            type="category"
            dataKey="name"
            // width={150} // Ensure enough width for expense categories
            tickMargin={10}
          />
          <Tooltip formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`}/>
          <Legend />
          <Bar dataKey="currentYear" name={isCurrentYear ? `Current Year (${currentYear})` : `Selected Year (${currentYear})`} fill="#87ABE2">
            <LabelList dataKey="currentYear" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`} />
          </Bar>
          <Bar dataKey="lastYear" name={isCurrentYear ? `Last Year (${previousYear})` : `Previous Year (${previousYear})`} fill="#C7B6F6">
            <LabelList dataKey="lastYear" position="right" formatter={(value) => `${new Intl.NumberFormat("en-US", {style: "currency", currency: currencies,}).format(value)}`} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ExpenseHistory;

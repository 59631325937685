import axios from "axios";
import * as XLSX from "xlsx";
import Modal from "react-modal";
import css from "./ClientsProjects.css";
import { DatePicker } from "rsuite";
import { parseISO, isValid } from 'date-fns';
import { DateRangePicker } from "rsuite";
import { useReactToPrint } from "react-to-print";
import moment from "moment-timezone";
import subDays from "date-fns/subDays";
import { toast } from "react-toastify";
import { Column } from "primereact/column";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { ThreeDots } from "react-loader-spinner";
import isEqual from "lodash/isEqual";
import { useSelector, useDispatch } from "react-redux";
import { renderClientProjectCTA } from "../../../helpers/invoiceHelpers.jsx";
import React, { useState, useRef, useEffect } from "react";
import PrintIcon from "../../../images/icons/printwhite.svg";
import DownloadImage from "../../../images/icons/download-icon.svg";
import { setHeaderText } from "../../../features/headerText/headerTextSlice.js";
import { ProjectCost, Roi, TrackedProjectHours, GetBudget, ProjectBudget } from "../../../helpers/ProjectHelpers.jsx";
import DateConverter from "../../../helpers/dateFormatChange.js";
import Calendar from "../../../helpers/dateComponents/Calendar";
import { useFetchProjectDetails } from "../../../components/TimeTracker/TimeTrackerProject/useProjectDetails.jsx";
import TruncateFilterFieldView from "../../../components/helpers/truncateFilterFieldView.js";
import NewProjectsModal from "./helpers/NewProjectsModal.jsx";
import ProjectOverview from "./helpers/ProjectOverview.jsx";
import { useGetClients, useGetDepartments, useGetTags } from "../../../components/TimeTracker/TimeTrackerOverview/useTimeTracker.jsx";
import { useTrackedProjectHours } from "../../../features/projects/ProjectList.jsx";
import ClientProjectsPrintTable from "./helpers/ClientProjectsPrintTable.jsx";
import TrackedProjectHoursByProjectId from "./helpers/TrackedProjectHoursByProjectId.jsx";
import TruncateFieldView from "../../../components/helpers/truncateFieldView.js";

const ranges = [
  {
    label: "today",
    value: new Date(), // Use a single date instead of an array
  },
  {
    label: "yesterday",
    value: subDays(new Date(), 1),
  },
];

const ClientsProjects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [clientsFilter, setClientsFilter] = useState("All");
  const [selectAllClient, setSelectAllClient] = useState(true);
  const [selectedClients, setSelectedClients] = useState([]);
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isClientModalDropdownOpen, setIsClientModalDropdownOpen] = useState(false);
  const [projectsFilter, setProjectsFilter] = useState("All");
  const [selectAllProject, setSelectAllProject] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [sortedProjects, setSortedProjects] = useState([]);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [isProjectModalDropdownOpen, setIsProjectModalDropdownOpen] = useState(false);
  const [deptsFilter, setDeptsFilter] = useState("All");
  const [selectAllDept, setSelectAllDept] = useState(true);
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [isDeptDropdownOpen, setIsDeptDropdownOpen] = useState(false);
  const [isDeptModalDropdownOpen, setIsDeptModalDropdownOpen] = useState(false);
  const [tagsFilter, setTagsFilter] = useState("All");
  const [selectAllTag, setSelectAllTag] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);
  const [isTagModalDropdownOpen, setIsTagModalDropdownOpen] = useState(false);
  const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showModalCalendar, setShowModalCalendar] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [showingDropdownOpen, setShowingDropdownOpen] = useState(false);
  const projectDropdownRef = useRef(null);
  const projectModalDropdownRef = useRef(null);
  const clientDropdownRef = useRef(null);
  const clientModalDropdownRef = useRef(null);
  const deptDropdownRef = useRef(null);
  const deptModalDropdownRef = useRef(null);
  const tagDropdownRef = useRef(null);
  const tagModalDropdownRef = useRef(null);
  const showingDropdownRef = useRef(null);
  const calendarRef = useRef(null);
  const calendarModalRef = useRef(null);
  var filteredData = [];
  const [sec2, setSec2] = useState(100);
  const [sec3, setSec3] = useState(100);
  const [selectAllByPage, setSelectAllByPage] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const componentRef = useRef();

  let token = null;
  const settings = JSON.parse(localStorage.getItem("settings"));
  const { user } = useSelector((state) => state.auth);
  const { appSettings } = useSelector((state) => state.appSettings);
  const { projectAdded } = useSelector((state) => state.project);
  const { forex } = useSelector((state) => state.forex);
  if (user) {
    if (user && user.token) {
      token = user.token;
    }
  }

  const { data: project = [], isLoading, refetch: refetchProjects } = useFetchProjectDetails(token);
  const { data: clients = [], isLoading: clientLoading, isError: clientError } = useGetClients(token);
  const { data: departments = [], isLoading: departmentLoading, isError: departmentError } = useGetDepartments(token);
  const { data: tags = [], isLoading: tagsLoading, isError: tagsError } = useGetTags(token);

  const renderProjectStatus = (rowData) => {
    // Determine the class based on the payment_status value
    let statusClass;
    let statusName;
    if (rowData.is_billable === true) {
      statusClass = "yes";
      statusName = "Yes";
    } else if (rowData.is_billable === false) {
      statusClass = "no";
      statusName = "No";
    }
    return (
      <span className={` ${statusClass}`}>{statusName}</span>
    );
  };

  useEffect(() => {
    refetchProjects();
    dispatch(setHeaderText("Projects"));
  }, [project, projectAdded]);

  if (Array.isArray(project)) {
    filteredData = project.filter((item) => {
      const clientName = (item.client_name.client_name || item.client_name.legal_entity_name);
      const currentDate = new Date().toISOString().slice(0, 10);


      if (clientsFilter !== "All" && !clientsFilter.includes(item.client_name._id)) {
        return false;
      }
      if (projectsFilter !== "All" && !projectsFilter.includes(item._id)) {
        return false;
      }

      if (dateRange !== null && dateRange.length === 2) {
        const [rangeStartDate, rangeEndDate] = dateRange;
        const createdAtDate = parseISO(item.created_at); // Assuming created_at is in ISO format
        rangeStartDate.setHours(0, 0, 0, 0);
        rangeEndDate.setHours(23, 59, 59, 999);

        console.log("rangeStartDate", rangeStartDate);
        console.log("rangeEndDate", rangeEndDate);
        // Check if created_at is within the selected date range
        if (
          createdAtDate < rangeStartDate ||
          createdAtDate > rangeEndDate
        ) {
          return false;
        }
      }
      // if (dateRange !== null && dateRange.length === 2) {
      //   const [rangeStartDate, rangeEndDate] = dateRange.map(date => {
      //     const parsedDate = parseISO(date);
      //     if (!isValid(parsedDate)) return null; // Check if date is valid
      //     if (date === rangeStartDate) {
      //       parsedDate.setHours(0, 0, 0, 0); // Start of the day
      //     } else {
      //       parsedDate.setHours(23, 59, 59, 999); // End of the day
      //     }
      //     return parsedDate;
      //   });

      //   if (!rangeStartDate || !rangeEndDate) return false; // Invalid date range

      //   const createdAtDate = parseISO(item.created_at); // Assuming created_at is in ISO format
      //   // Check if created_at is within the selected date range
      //   if (createdAtDate < rangeStartDate || createdAtDate > rangeEndDate) {
      //     return false;
      //   }
      // }
      return true;
    });
  } else {
    console.warn("Project data is empty or undefined.");
  }

  const totalResults = filteredData.length;

  const handleCheckboxClientChange = (clientId) => {
    const updatedSelection = [...selectedClients];
    const index = updatedSelection.indexOf(clientId);
    let filteredProjects;

    if (index === -1) {
      // Add to selection
      updatedSelection.push(clientId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    if (Array.isArray(project)) {
      filteredProjects = project.filter(project => updatedSelection.includes(project.client_name._id));
    }
    setSelectedProjects(filteredProjects.map(project => project._id));
    setSortedProjects(filteredProjects);
    setSelectedClients(updatedSelection);
  };

  const handleSelectAllClientChange = () => {
    if (!selectAllClient) {
      const allClientIds = clients.map(client => client._id);
      let filteredProjects;
      setSelectedClients(allClientIds);

      // Filter projects based on all selected clients
      if (Array.isArray(project)) {
        filteredProjects = project.filter(project => allClientIds.includes(project.client_name._id));
      }
      setSelectedProjects(filteredProjects.map(project => project._id));
      setSortedProjects(filteredProjects);

    } else {
      setSelectedClients([]);
      setSelectedProjects([]);
      setSortedProjects([]);
    }
    setSelectAllClient(!selectAllClient);
  };

  useEffect(() => {
    setSelectAllClient(selectedClients.length === clients.length);
    if (selectedClients.length === clients.length) {
      setClientsFilter("All");
    } else {
      setClientsFilter(selectedClients);
    }
  }, [selectedClients, clients]);

  const toggleClientDropdown = () => {
    setIsClientDropdownOpen(!isClientDropdownOpen);
  };

  const toggleClientModalDropdown = () => {
    setIsClientModalDropdownOpen(!isClientModalDropdownOpen);
  };

  const handleCheckboxProjectChange = (projectId) => {
    const updatedSelection = [...selectedProjects];
    const index = updatedSelection.indexOf(projectId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(projectId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedProjects(updatedSelection);
  };

  const handleSelectAllProjectChange = () => {
    if (!selectAllProject) {
      setSelectedProjects(sortedProjects.map((project) => project._id));
    } else {
      setSelectedProjects([]);
    }
    setSelectAllProject(!selectAllProject);
  };

  useEffect(() => {
    setSelectAllProject(selectedProjects.length === sortedProjects.length);
    if (selectedProjects.length === sortedProjects.length) {
      setProjectsFilter("All");
    } else {
      setProjectsFilter(selectedProjects);
    }
  }, [selectedProjects, project]);

  const toggleProjectDropdown = () => {
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  };

  const toggleProjectModalDropdown = () => {
    setIsProjectModalDropdownOpen(!isProjectModalDropdownOpen);
  };

  const handleCheckboxDeptChange = (deptId) => {
    const updatedSelection = [...selectedDepts];
    const index = updatedSelection.indexOf(deptId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(deptId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedDepts(updatedSelection);
  };

  const handleSelectAllDeptChange = () => {
    if (!selectAllDept) {
      setSelectedDepts(departments.map((dept) => dept._id));
    } else {
      setSelectedDepts([]);
    }
    setSelectAllDept(!selectAllDept);
  };

  useEffect(() => {
    setSelectAllDept(selectedDepts.length === departments.length);
    if (selectedDepts.length === departments.length) {
      setDeptsFilter("All");
    } else {
      setDeptsFilter(selectedDepts);
    }
  }, [selectedDepts, departments]);

  const toggleDeptDropdown = () => {
    setIsDeptDropdownOpen(!isDeptDropdownOpen);
  };

  const toggleDeptModalDropdown = () => {
    setIsDeptModalDropdownOpen(!isDeptModalDropdownOpen);
  };

  const handleCheckboxTagChange = (tagId) => {
    const updatedSelection = [...selectedTags];
    const index = updatedSelection.indexOf(tagId);

    if (index === -1) {
      // Add to selection
      updatedSelection.push(tagId);
    } else {
      // Remove from selection
      updatedSelection.splice(index, 1);
    }

    setSelectedTags(updatedSelection);
  };

  const handleSelectAllTagChange = () => {
    if (!selectAllTag) {
      setSelectedTags(tags.map((tag) => tag._id));
    } else {
      setSelectedTags([]);
    }
    setSelectAllTag(!selectAllTag);
  };

  useEffect(() => {
    setSelectAllTag(selectedTags.length === tags.length);
    if (selectedTags.length === tags.length) {
      setTagsFilter("All");
    } else {
      setTagsFilter(selectedTags);
    }
  }, [selectedTags, tags]);

  const toggleTagDropdown = () => {
    setIsTagDropdownOpen(!isTagDropdownOpen);
  };

  const toggleTagModalDropdown = () => {
    setIsTagModalDropdownOpen(!isTagModalDropdownOpen);
  };

  const handleClickOutsideDropdown = (event) => {
    const isClickInsideProjectDropdown = projectDropdownRef.current && projectDropdownRef.current.contains(event.target);
    const isClickInsideProjectModalDropdown = projectModalDropdownRef.current && projectModalDropdownRef.current.contains(event.target);
    const isClickInsideClientDropdown = clientDropdownRef.current && clientDropdownRef.current.contains(event.target);
    const isClickInsideClientModalDropdown = clientModalDropdownRef.current && clientModalDropdownRef.current.contains(event.target);
    const isClickInsideDeptDropdown = deptDropdownRef.current && deptDropdownRef.current.contains(event.target);
    const isClickInsideDeptModalDropdown = deptModalDropdownRef.current && deptModalDropdownRef.current.contains(event.target);
    const isClickInsideTagDropdown = tagDropdownRef.current && tagDropdownRef.current.contains(event.target);
    const isClickInsideTagModalDropdown = tagModalDropdownRef.current && tagModalDropdownRef.current.contains(event.target);
    const isClickInsideShowingDropdown = showingDropdownRef.current && showingDropdownRef.current.contains(event.target);
    const isClickInsideCalendarDropdown = calendarRef.current && calendarRef.current.contains(event.target);
    const isClickInsideModalCalendarDropdown = calendarModalRef.current && calendarModalRef.current.contains(event.target);

    if (!isClickInsideProjectDropdown) {
      setIsProjectDropdownOpen(false);
    }
    if (!isClickInsideProjectModalDropdown) {
      setIsProjectModalDropdownOpen(false);
    }
    if (!isClickInsideClientDropdown) {
      setIsClientDropdownOpen(false);
    }
    if (!isClickInsideClientModalDropdown) {
      setIsClientModalDropdownOpen(false);
    }
    if (!isClickInsideDeptDropdown) {
      setIsDeptDropdownOpen(false);
    }
    if (!isClickInsideDeptModalDropdown) {
      setIsDeptModalDropdownOpen(false);
    }
    if (!isClickInsideTagDropdown) {
      setIsTagDropdownOpen(false);
    }
    if (!isClickInsideTagModalDropdown) {
      setIsTagModalDropdownOpen(false);
    }
    if (!isClickInsideShowingDropdown) {
      setShowingDropdownOpen(false);
    }
    if (!isClickInsideCalendarDropdown) {
      setShowCalendar(false);
    }
    if (!isClickInsideModalCalendarDropdown) {
      setShowModalCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideDropdown);

    return () => {
      document.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, []);

  // const handleSelect = (ranges) => {
  //   setDateRange(ranges);
  //   if (ranges === null) {
  //     // const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  //     setDateRange(null);
  //   }
  // };

  const onDateChange = (ranges) => {
    // moment.tz(item.date,'YYYY-MM-DD HH:mm:ss',global.config.getTimeZone()).format()
    const dateRangeArray = [ranges.startDate, ranges.endDate];
    setDateRange(dateRangeArray);
    setShowCalendar(false);
    setShowModalCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const cancelCalendar = () => {
    setShowCalendar(false);
  };

  const toggleModalCalendar = () => {
    setShowModalCalendar(!showModalCalendar);
  };

  const cancelModalCalendar = () => {
    setShowModalCalendar(false);
  };

  const applyFilters = () => {
    setShowFilterModal(false);
  };

  const clearFilters = () => {
    setSelectedClients(clients.map((client) => client._id));
    setSelectedProjects(project.map((project) => project._id));
    setSelectedDepts(departments.map((dept) => dept._id));
    setSelectedTags(tags.map((tag) => tag._id));
    setDateRange(null);
    setShowFilterModal(false);
  };

  const checkAndShowConfirmationModal = () => {
    const isClientsModified = !isEqual(selectedClients, clients.map((client) => client._id));
    const isProjectsModified = !isEqual(selectedProjects, project.map((project) => project._id));
    const isDeptsModified = !isEqual(selectedDepts, departments.map((dept) => dept._id));
    const isTagsModified = !isEqual(selectedTags, tags.map((tag) => tag._id));
    const isDateRangeModified = !isEqual(dateRange, null);

    if (
      isClientsModified ||
      isProjectsModified ||
      isDeptsModified ||
      isTagsModified ||
      isDateRangeModified
    ) {
      setShowConfirmationModal(true);
    } else {
      setShowFilterModal(false);
    }
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleApplyConfirm = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(false);
  };

  const toggleFilterModal = () => {
    setShowConfirmationModal(false);
    setShowFilterModal(!showFilterModal);
  };

  const disableInput = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setSelectedClients(clients.map((client) => client._id));
    if (Array.isArray(project)) {
      setSelectedProjects(project.map((project) => project._id));
    }
    setSortedProjects(project);
    setSelectedDepts(departments.map((dept) => dept._id));
    setSelectedTags(tags.map((tag) => tag._id));
  }, [clients, project, tags]);

  const changeDurationFormat = sec => {
    let second = sec;
    const hours = Math.floor(second / 3600);
    const minutes = Math.floor((second % 3600) / 60);
    const seconds = second % 60;
    const formattedDuration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return formattedDuration;
  };

  const TrackedProject = (projectId) => {
    const { data: totalHours = [], isLoading: loading, refetch: refetchTotalHours } = useTrackedProjectHours(projectId, token);
    return (
      <div>
        {loading ? (
          <div className="outter-load-table">
            <ThreeDots
              height="18"
              width="20"
              radius="9"
              color="#6479f9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <span>{totalHours ? changeDurationFormat(totalHours) : '00:00:00'}</span> // Display the tracked hours once loaded
        )}
      </div>
    );
  }

  function ConvertHelper(currencyFrom, currencyTo, amount, forex) {
    const currencyFromRate = forex[currencyFrom];
    const currencyToRate = forex[currencyTo];
    const amountConvertedUSD = amount / currencyFromRate;
    const amountConvertTo = amountConvertedUSD * currencyToRate;
    return amountConvertTo;
  }

  const exportToExcel = () => {
    if (filteredData.length === 0) {
      toast.error("No data to export.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const now = new Date();
      const formattedDate =
        now.getFullYear() +
        "-" +
        (now.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      const formattedTime =
        now.getHours().toString().padStart(2, "0") +
        ":" +
        now.getMinutes().toString().padStart(2, "0") +
        ":" +
        now.getSeconds().toString().padStart(2, "0");
      const modifiedLog = filteredData;

      const final = [];
      for (const item of filteredData) {

        final.push({
          "Project": item.project_name ? item.project_name : "",
          "Date Created": item.created_at ? moment(item.created_at).tz(global.config.getTimeZone()).format("DD/MM/YYYY") : "",
          "Tracked Hours": item.project_name ? <TrackedProjectHours rowData={item} /> : "",
          "Budget": item.budget ? item.budget : "0.00",
          "Cost": item.project_name ? <ProjectCost rowData={item} /> : "",
          "ROI": item.budget ? <Roi rowData={item} /> : "",
          "Billable": item.is_billable === true ? "Yes" : "No"
        });
      }
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(final);
      const colWidths = [
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 120 },
        { wpx: 100 },
        { wpx: 135 },
        { wpx: 100 },
        { wpx: 125 },
      ];
      worksheet["!cols"] = colWidths;
      // Add alignment to center align the first row and increase font size
      const firstRow = XLSX.utils.decode_range(worksheet["!ref"]).s.r;
      for (let i = 0; i < colWidths.length; i++) {
        const cell = XLSX.utils.encode_cell({ r: firstRow, c: i });
        const style = worksheet[cell]?.s || {};
        style.alignment = style.alignment || {};
        style.alignment.horizontal = "center !important";
        style.alignment.vertical = "center !important";
        style.font = { size: 14, bold: true };
        worksheet[cell] = { ...worksheet[cell], s: style };
      }

      // Set border for active area and increase font size
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let row = range.s.r + 1; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cell = XLSX.utils.encode_cell({ r: row, c: col });
          const style = worksheet[cell]?.s || {};
          style.font = { size: 12 };
          worksheet[cell] = { ...worksheet[cell], s: style };
        }
      }
      global.config.activityLog(
        window.location.href,
        "Project",
        "Project Data exported to Excel"
      );
      const fileName = `Project_Report_${formattedDate}_${formattedTime}.xlsx`;
      worksheet["!autofilter"] = { ref: range };
      XLSX.utils.book_append_sheet(workbook, worksheet, "Project Report");
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.log(error);
      global.config.slackMessage(error.toString());
      global.config.activityLog(
        window.location.href,
        "Project",
        "Error occurred while exporting PROJECT table to Excel"
      );
      alert("Error occurred while exporting to Excel.");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "project-data",
  });

  const handlePrintButtonClick = () => {
    if (filteredData.length === 0) {
      toast.error("No data to print.", {
        position: "bottom-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    var htmlc = `<h1>TEST</h1>`
    handlePrint(filteredData);
    global.config.activityLog(
      window.location.href,
      "Timer",
      "Timer Data Printed"
    );
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0); // Set the current page to 0 when changing rows per page
  };

  const onPageChange = (e) => {
    const newPage = e.page;
    // Store the current "Select All" state for the current page
    setSelectAllByPage((prevSelectAllByPage) => ({
      ...prevSelectAllByPage,
    }));
    setCurrentPage(e.page);
  };

  return (
    <div className="outer-invoice-clinet-section">
      <div className="invoice-outer-invoice">

        <div className="invoice-top">
          <div className="new-top-flter">
            <div className="button-sec-invoice">
              <NewProjectsModal />
              {/* invoice type madal end */}
              <a onClick={exportToExcel}>
                <span className="icon-invoice">
                  <img src={DownloadImage} alt="Export" />
                </span>
                Export Data
              </a>
              <a onClick={handlePrintButtonClick} className="button-space">
                <span className="icon-print">
                  <img src={PrintIcon} alt="Export" />
                </span>
                Print
              </a>
            </div>
            <div className="filter-invoice-right">
              <div className="filter-inner showing-result">
                <label>Showing</label>

                <div
                  ref={showingDropdownRef}
                  className="select-modal-mainM custom-dropdown-user-new-outer"
                >
                  <div
                    className="selected-option custom-dropdown-user-new"
                    onClick={() => setShowingDropdownOpen(!showingDropdownOpen)}
                  >
                    {totalResults < 10
                      ? totalResults.toString()
                      : rowsPerPage.toString()}
                  </div>
                  {showingDropdownOpen && (
                    <div className="custom-dropdown-new">
                      <ul className="showing-options-list">
                        {totalResults >= 10 && (
                          <li
                            className={rowsPerPage === 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 10 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            10
                          </li>
                        )}
                        {totalResults >= 20 && (
                          <li
                            className={rowsPerPage === 20 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 20 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            20
                          </li>
                        )}
                        {totalResults >= 30 && (
                          <li
                            className={rowsPerPage === 30 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 30 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            30
                          </li>
                        )}
                        {totalResults >= 40 && (
                          <li
                            className={rowsPerPage === 40 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({ target: { value: 40 } });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            40
                          </li>
                        )}
                        {totalResults > 10 && (
                          <li
                            className={
                              totalResults > 10 && rowsPerPage === totalResults
                                ? "selected-item"
                                : ""
                            }
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: totalResults },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            ALL
                          </li>
                        )}
                        {totalResults < 10 ? (
                          <li
                            className={totalResults < 10 ? "selected-item" : ""}
                            onClick={() => {
                              handleRowsPerPageChange({
                                target: { value: totalResults },
                              });
                              setShowingDropdownOpen(false);
                            }}
                          >
                            {totalResults}
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  )}
                </div>
                <label>of {totalResults} Results</label>
              </div>
            </div>
          </div>

          <div className="invoice-filter">
            <div className="invoice-filter-inner-section no-flter-all client-project-filter">
              <div className="filter-inners users">
                <label for="user">Client</label>
                <div className="custom-dropdown1" ref={clientDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleClientDropdown}>
                      {selectedClients.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedClients[0]}>
                            {selectedClients.length === clients.length
                              ? 'All'
                              : (
                                <>
                                  <TruncateFilterFieldView textContent={clients.find((item) => item._id === selectedClients[0])?.client_name} />
                                  {selectedClients.length > 1 && selectedClients.length !== clients.length && (
                                    <div className="selected-option-count">
                                      {selectedClients.length - 1}+
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    {isClientDropdownOpen && (
                      <div className="dropdown-list1">
                        {clients.length > 0 && (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllClient}
                                onChange={handleSelectAllClientChange}
                              />
                              All
                            </label>
                            {clients
                              .slice()
                              .sort((a, b) =>
                                a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
                              )
                              .map((client) => (
                                <label className="checkbox-label1" key={client._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedClients.includes(client._id)}
                                    onChange={() => handleCheckboxClientChange(client._id)}
                                  />
                                  {client.client_name && `${client.client_name}`}
                                </label>
                              ))}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="filter-inner invoice-status">
                <label>Project</label>
                <div className="custom-dropdown1" ref={projectDropdownRef}>
                  <div className="drop-down-inner">
                    <div className="selected-options1" onClick={toggleProjectDropdown}>
                      {selectedProjects.length === 0 ? (
                        <div className="placeholder">Select</div>
                      ) : (
                        <>
                          <div className="selected-option1" key={selectedProjects[0]}>
                            {selectedProjects.length === sortedProjects.length
                              ? 'All'
                              : (
                                <>
                                  <TruncateFilterFieldView textContent={sortedProjects && Array.isArray(sortedProjects) && sortedProjects.find((item) => item._id === selectedProjects[0])?.project_name} />
                                  {selectedProjects.length > 1 && selectedProjects.length !== sortedProjects.length && (
                                    <div className="selected-option-count">
                                      {selectedProjects.length - 1}+
                                    </div>
                                  )}
                                </>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                    {isProjectDropdownOpen && (
                      <div className="dropdown-list1">
                        {sortedProjects.length > 0 ? (
                          <>
                            <label className="checkbox-label1" key="selectAll">
                              <input
                                type="checkbox"
                                checked={selectAllProject}
                                onChange={handleSelectAllProjectChange}
                              />
                              All
                            </label>
                            {sortedProjects
                              .slice()
                              .sort((a, b) =>
                                a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase())
                              )
                              .map((project) => (
                                <label className="checkbox-label1" key={project._id}>
                                  <input
                                    type="checkbox"
                                    checked={selectedProjects.includes(project._id)}
                                    onChange={() => handleCheckboxProjectChange(project._id)}
                                  />
                                  {project.project_name && `${project.project_name}`}
                                </label>
                              ))}
                          </>
                        ) : (
                          'No projects found'
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="filter-inner due-date">
                <label htmlFor="dueDate" className="calendar-icon">
                  Date Range
                </label>
                <div className="date-picker-outer date-picker-outer-all" ref={calendarRef}>
                  <div className="custom-picker-icon custom-picker-icon-all">
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 14 14"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class="rs-picker-toggle-caret rs-icon"
                      aria-label="calendar"
                      data-category="legacy"
                    >
                      <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                    </svg>
                  </div>
                  <div className="custoom-icon-calender custoom-icon-calender-all">
                    {/* <DateRangePicker
                      showOneCalendar
                      id="date-range"
                      format="dd/MM/yyyy"
                      placeholder="All Time"
                      value={dateRange}
                      onChange={handleSelect}
                      placement="auto"
                      className="tracker-date-picker"
                      allowInput={false}
                      onKeyDown={disableInput}
                      renderStaticRangeLabel={({ range }) => {
                        const startDate = range.startDate.toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                        });
                        const endDate = range.endDate.toLocaleDateString("en-US", {
                          day: "numeric",
                          month: "short",
                        });

                        const label = `${startDate} to ${endDate}`;

                        return label.replace("~", "to");
                      }}
                      isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                      timeZone={global.config.getTimeZone()}
                    /> */}
                    <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                      <input
                        type="text"
                        value={
                          dateRange ?
                            `${new Date(dateRange[0]).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })} to ${new Date(dateRange[1]).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}`
                            : null
                        }
                        id="invoiceId"
                        name="invoiceId"
                        placeholder="All Time"
                        onClick={toggleCalendar}
                        autocomplete="off"
                      />
                    </div>
                    <div className="date-range-new left-align">
                      {showCalendar && <Calendar onCancel={cancelCalendar} onChange={onDateChange} initialRange={{ startDate: dateRange ? dateRange[0] : null, endDate: dateRange ? dateRange[1] : null }} />}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="filter-invoice-right apply-fliter-right apply-fliter-right-project">
              <button onClick={toggleFilterModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="17"
                  viewBox="0 0 13 17"
                  fill="none"
                >
                  <path
                    d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z"
                    fill="#6479F8"
                  />
                  <path
                    d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z"
                    fill="#6479F8"
                  />
                </svg>
                All Filter
              </button>
            </div>
            <Modal
              isOpen={showFilterModal}
              onRequestClose={toggleFilterModal}
              contentLabel="Filter Modal"
              className="new-filter-modal"
              style={{
                content: {
                  height: "50%",
                  width: "50%",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-25%, -25%)",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  // overflow: "auto",
                },
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <div className="new-nodal-invoice-outer-sec">

                <div className="new-modal-top-sec">
                  <div className="new-modal-heading">
                    All filters
                  </div>
                  <a className="close-image-clr1" onClick={checkAndShowConfirmationModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path d="M1 13L13 1" stroke="black" />
                      <path d="M1 0.999999L13 13" stroke="black" />
                    </svg>{" "}
                  </a>
                </div>
                <div className="invoice-filter-inner-section">
                  <div className="filter-inner user">
                    <label for="user">Client</label>
                    <div className="custom-dropdown1" ref={clientModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleClientModalDropdown}>
                          {selectedClients.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedClients[0]}>
                                {selectedClients.length === clients.length
                                  ? 'All'
                                  : (
                                    <>
                                      <TruncateFilterFieldView textContent={clients.find((item) => item._id === selectedClients[0])?.client_name} />
                                      {selectedClients.length > 1 && selectedClients.length !== clients.length && (
                                        <div className="selected-option-count">
                                          {selectedClients.length - 1}+
                                        </div>
                                      )}
                                    </>
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        {isClientModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {clients.length > 0 && (
                              <>
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllClient}
                                    onChange={handleSelectAllClientChange}
                                  />
                                  All
                                </label>
                                {clients
                                  .slice()
                                  .sort((a, b) =>
                                    a.client_name.toLowerCase().localeCompare(b.client_name.toLowerCase())
                                  )
                                  .map((client) => (
                                    <label className="checkbox-label1" key={client._id}>
                                      <input
                                        type="checkbox"
                                        checked={selectedClients.includes(client._id)}
                                        onChange={() => handleCheckboxClientChange(client._id)}
                                      />
                                      {client.client_name && `${client.client_name}`}
                                    </label>
                                  ))}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="filter-inner invoice-status">
                    <label>Project</label>
                    <div className="custom-dropdown1" ref={projectModalDropdownRef}>
                      <div className="drop-down-inner">
                        <div className="selected-options1" onClick={toggleProjectModalDropdown}>
                          {selectedProjects.length === 0 ? (
                            <div className="placeholder">Select</div>
                          ) : (
                            <>
                              <div className="selected-option1" key={selectedProjects[0]}>
                                {selectedProjects.length === sortedProjects.length
                                  ? 'All'
                                  : (
                                    <>
                                      <TruncateFilterFieldView textContent={sortedProjects && Array.isArray(sortedProjects) && sortedProjects.find((item) => item._id === selectedProjects[0])?.project_name} />
                                      {selectedProjects.length > 1 && selectedProjects.length !== sortedProjects.length && (
                                        <div className="selected-option-count">
                                          {selectedProjects.length - 1}+
                                        </div>
                                      )}
                                    </>
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        {isProjectModalDropdownOpen && (
                          <div className="dropdown-list1">
                            {sortedProjects.length > 0 ? (
                              <>
                                <label className="checkbox-label1" key="selectAll">
                                  <input
                                    type="checkbox"
                                    checked={selectAllProject}
                                    onChange={handleSelectAllProjectChange}
                                  />
                                  All
                                </label>
                                {sortedProjects
                                  .slice()
                                  .sort((a, b) =>
                                    a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase())
                                  )
                                  .map((project) => (
                                    <label className="checkbox-label1" key={project._id}>
                                      <input
                                        type="checkbox"
                                        checked={selectedProjects.includes(project._id)}
                                        onChange={() => handleCheckboxProjectChange(project._id)}
                                      />
                                      {project.project_name && `${project.project_name}`}
                                    </label>
                                  ))}
                              </>
                            ) : (
                              <label className="checkbox-label1">
                                No projects found
                              </label>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="filter-inner due-date">
                    <label htmlFor="dueDate" className="calendar-icon">
                      Date Range
                    </label>
                    <div className="date-picker-outer date-picker-outer-all" ref={calendarModalRef}>
                      <div className="custom-picker-icon custom-picker-icon-all">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 14 14"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          class="rs-picker-toggle-caret rs-icon"
                          aria-label="calendar"
                          data-category="legacy"
                        >
                          <path d="M1 4v8.5a.5.5 0 00.5.5H8c0-.128.049-.256.146-.354.555-.555.854-1.6.854-3.146a.5.5 0 01.621-.485l.119.03A2.623 2.623 0 0012.999 6.5V4h-12zm3-2h6V0h1v2h1.5A1.5 1.5 0 0114 3.5v3a3.623 3.623 0 01-4.015 3.603c-.064 1.245-.335 2.212-.831 2.898H12.5a.5.5 0 00.5-.5v-2a.5.5 0 011 0v2a1.5 1.5 0 01-1.5 1.5h-11a1.5 1.5 0 01-1.5-1.5v-9a1.5 1.5 0 011.5-1.5H3v-2h1v2zm5 4V5h1v1h2v1h-2v1H9V7H7v2h1v1H7v2H6v-2H4v2H3v-2H1V9h2V7H1V6h2V5h1v1h2V5h1v1h2zM6 9V7H4v2h2z"></path>
                        </svg>
                      </div>
                      <div className="custoom-icon-calender custoom-icon-calender-all">
                        {/* <DateRangePicker
                          showOneCalendar
                          id="date-range"
                          format="dd/MM/yyyy"
                          placeholder="All Time"
                          value={dateRange}
                          onChange={handleSelect}
                          placement="auto"
                          className="tracker-date-picker"
                          allowInput={false}
                          onKeyDown={disableInput}
                          renderStaticRangeLabel={({ range }) => {
                            const startDate = range.startDate.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "short",
                            });
                            const endDate = range.endDate.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "short",
                            });

                            const label = `${startDate} to ${endDate}`;

                            return label.replace("~", "to");
                          }}
                          isoWeek={appSettings.weekStartDay && appSettings.weekStartDay === 'Monday'}
                          timeZone={global.config.getTimeZone()}
                        /> */}
                        <div className="custoom-icon-calender custoom-icon-calender-all custom-date-range">
                          <input
                            type="text"
                            value={
                              dateRange ?
                                `${new Date(dateRange[0]).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })} to ${new Date(dateRange[1]).toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                })}`
                                : null
                            }
                            id="invoiceId"
                            name="invoiceId"
                            placeholder="All Time"
                            onClick={toggleModalCalendar}
                            autocomplete="off"
                          />
                        </div>
                        <div className="date-range-new">
                          {showModalCalendar && <Calendar onCancel={cancelModalCalendar} onChange={onDateChange} initialRange={{ startDate: dateRange ? dateRange[0] : null, endDate: dateRange ? dateRange[1] : null }} />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                className="delete-modal"
                isOpen={showConfirmationModal}
                onRequestClose={handleCancel}
                contentLabel="Confirm Delete"
                style={{
                  content: {
                    height: "41%",
                    width: "30%",
                    position: "fixed",
                    top: "36%",
                    left: "50%",
                    transform: "translate(-24.75%, 0%)",
                  },
                }}
              >
                <div className="delete-modal-content">
                  <h2>Apply or Discard changes?</h2>
                  <p>
                    Do you want to Apply or Discard filter changes?
                  </p>

                  <div className="delete-modal-buttons">
                    <button className="delete" onClick={handleApplyConfirm} >
                      Apply Changes
                    </button>
                    <button onClick={clearFilters}>
                      Discard Changes
                    </button>
                  </div>
                </div>
              </Modal>
              <div className="filter-buttons">
                <button className="clear-all" onClick={clearFilters}>Clear All</button>
                <button className="apply-filter" onClick={applyFilters}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                    <path d="M11.7363 8.81108C11.4276 9.81451 10.4925 10.5444 9.38889 10.5444C8.28471 10.5444 7.35016 9.8141 7.04147 8.81108L0.722221 8.81108C0.323349 8.81108 -1.07845e-06 8.48773 -1.06101e-06 8.08886C-1.04357e-06 7.68998 0.323349 7.36663 0.722221 7.36663L7.04144 7.36663C7.35022 6.3632 8.28529 5.6333 9.38889 5.6333C10.4931 5.6333 11.4276 6.36362 11.7363 7.36663L12.2778 7.36663C12.6767 7.36663 13 7.68998 13 8.08886C13 8.48773 12.6766 8.81108 12.2778 8.81108L11.7363 8.81108ZM9.38889 7.07775C8.83162 7.07775 8.37778 7.53123 8.37778 8.08886C8.37778 8.64613 8.83127 9.09997 9.38889 9.09997C9.94616 9.09997 10.4 8.64648 10.4 8.08886C10.4 7.53158 9.94651 7.07775 9.38889 7.07775Z" fill="white" />
                    <path d="M5.38078 3.17778C5.072 4.18121 4.13693 4.91111 3.03333 4.91111C1.92882 4.91111 0.994035 4.18035 0.68563 3.17687C0.303759 3.15781 -1.07791e-06 2.84216 -1.06101e-06 2.45556C-1.04411e-06 2.06896 0.303748 1.75331 0.685606 1.73425C0.994098 0.730343 1.9294 -4.83911e-07 3.03333 -4.35657e-07C4.13752 -3.87392e-07 5.07206 0.730315 5.38076 1.73333L12.2778 1.73333C12.6767 1.73333 13 2.05668 13 2.45556C13 2.85443 12.6766 3.17778 12.2778 3.17778L5.38078 3.17778ZM3.03333 1.44444C2.47606 1.44444 2.02222 1.89793 2.02222 2.45556C2.02222 3.01283 2.47571 3.46667 3.03333 3.46667C3.5906 3.46667 4.04444 3.01318 4.04444 2.45556C4.04444 1.89828 3.59096 1.44444 3.03333 1.44444Z" fill="white" />
                    <path d="M7.403 14.4444C7.09422 15.4478 6.15916 16.1777 5.05556 16.1777C3.95137 16.1777 3.01682 15.4474 2.70813 14.4444L0.722221 14.4444C0.323349 14.4444 -1.07845e-06 14.121 -1.06101e-06 13.7222C-1.04357e-06 13.3233 0.323349 12.9999 0.722221 12.9999L2.70811 12.9999C3.01689 11.9965 3.95195 11.2666 5.05556 11.2666C6.15974 11.2666 7.09429 11.9969 7.40298 12.9999L12.2778 12.9999C12.6767 12.9999 13 13.3233 13 13.7222C13 14.121 12.6766 14.4444 12.2778 14.4444L7.403 14.4444ZM5.05556 12.711C4.49828 12.711 4.04444 13.1645 4.04444 13.7222C4.04444 14.2794 4.49793 14.7333 5.05556 14.7333C5.61283 14.7333 6.06667 14.2798 6.06667 13.7222C6.06667 13.1649 5.61318 12.711 5.05556 12.711Z" fill="white" />
                  </svg>
                  Apply Filters
                </button>
              </div>
            </Modal>
          </div>
        </div>
        <div className="total-sec-overview">
          <ProjectOverview projectIds={selectedProjects} clientIds={selectedClients} dateRange={dateRange} isLoading={isLoading} />
        </div>
        <div className="card-outer1">
          <div className="card-outer">
            <div className="card">
              {isLoading ? (

                <div className="outter-load-table">
                  <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#6479f9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>

              ) : (
                <DataTable
                  value={filteredData}
                  removableSort
                  tableStyle={{
                    minWidth: "50rem",
                    width: "100%",
                    textAlign: "left",
                  }}
                  paginator={filteredData.length > 10}
                  paginatorClassName="recent-invoices-pagination"
                  rows={rowsPerPage} // Set the number of rows per page
                  first={currentPage * rowsPerPage} // Set the current page using currentPage
                  onPage={onPageChange} // Update currentPage when changing pages
                >
                  <Column
                    field="project_name"
                    header="Project"
                    body={(rowData) => (
                      <TruncateFieldView textContent={rowData?.project_name} />
                    )}
                    className="fixed-sec"
                    sortable
                    style={{ paddingRight: "44px", minWidth: "230px", }}
                  ></Column>
                  <Column
                    field="created_at"
                    body={(rowData) => (
                      <DateConverter
                        date={rowData.created_at}
                      />
                    )}
                    header="Date Created"
                    // className="fixed-sec3"
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="total_hours"
                    header="Budgeted Hours"
                    className="fixed-sec4"
                    body={(rowData) => {
                      const totalHours = rowData.total_hours;
                      const totalSeconds = totalHours !== undefined && totalHours !== null ? totalHours * 3600 : 0;
                      return changeDurationFormat(totalSeconds); // Format it
                    }}
                    sortable
                    // sortFunction={(e) => {
                    //   const dataCopy = [...filteredData];
                    //   dataCopy.sort((rowData1, rowData2) => {
                    //     const totalA = rowData1.total_hours !== undefined && rowData1.total_hours !== null ? rowData1.total_hours : 0;
                    //     const totalB = rowData2.total_hours !== undefined && rowData2.total_hours !== null ? rowData2.total_hours : 0;
                    //     return totalA - totalB;
                    //   });
                    //   return dataCopy;
                    // }}
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="tracked_hours"
                    header="Tracked Hours"
                    className="fixed-sec4"
                    body={TrackedProjectHours}
                    // sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="budget"
                    header="Budget"
                    // body={(rowData) => {
                    //   const { client_name, budget } = rowData;
                    //   if (client_name && client_name.preferred_currency) {
                    //     return new Intl.NumberFormat("en-US", {
                    //       style: "currency",
                    //       currency: settings.currency,
                    //     }).format(ConvertHelper(client_name?.preferred_currency, settings.currency, budget, forex));
                    //   } else {
                    //     return new Intl.NumberFormat("en-US", {
                    //       style: "currency",
                    //       currency: settings.currency,
                    //     }).format(ConvertHelper("USD", settings.currency, budget, forex));
                    //   }
                    // }}
                    body={ProjectBudget}
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="cost"
                    header="Cost"
                    body={ProjectCost}
                    // className=""
                    // sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="roi"
                    header="ROI"
                    body={Roi}
                    // className="amount-bold"
                    // sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="is_billable"
                    header="Billable"
                    body={(rowData) => renderProjectStatus(rowData)}
                    className=""
                    sortable
                    style={{ paddingRight: "44px" }}
                  ></Column>
                  <Column
                    field="_id"
                    body={renderClientProjectCTA}
                    className="fixed-sec2"
                    style={{
                      paddingRight: "45px",
                      minWidth: "121.73px",
                    }}
                  ></Column>
                </DataTable>
              )}
            </div>
            <div className="print-sec-invoice" style={{ display: "none" }}>
              <ClientProjectsPrintTable filteredData={filteredData} isLoading={isLoading} componentRef={componentRef} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClientsProjects;
